export default {
  colors: {
    "bg-emphasized": {
      default: "gray.300",
      _dark: "gray.600",
    },
    warning: {
      default: "orange.500",
      _dark: "orange.200",
    },
    info: {
      default: "blue.500",
      _dark: "blue.200",
    },
    "error-on-canvas": {
      default: "red.500",
      _dark: "red.300",
    },
    helpText: {
      default: "gray.500",
      _dark: "whiteAlpha.600",
    },
    buttonHover: {
      default: "blackAlpha.100",
      _dark: "whiteAlpha.100",
    },
    buttonFocus: {
      default: "blackAlpha.200",
      _dark: "whiteAlpha.200",
    },
    buttonActive: {
      default: "blackAlpha.300",
      _dark: "whiteAlpha.300",
    },
  },
};
