import { theme as baseTheme } from "@chakra-ui/react";

const variants = {
  warning: (props) =>
    props.theme.components["Button"]["variants"]["solid"]({
      ...props,
      variant: "solid",
      colorScheme: "orange",
    }),
  info: (props) =>
    props.theme.components["Button"]["variants"]["outline"]({
      ...props,
      variant: "solid",
      colorScheme: "blue",
    }),
  // Revert pro theme's outline and ghost, I don't really like them
  outline: baseTheme.components["Button"]["variants"]["outline"],
  ghost: baseTheme.components["Button"]["variants"]["ghost"],
};

export default {
  variants,
};
