import { useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { useMatchesData } from "./useMatches.js";

export function useFlashMessage() {
  const { flashMessage } = useMatchesData("root", false);
  const toast = useToast();

  useEffect(() => {
    if (flashMessage) {
      toast({
        duration: 4000,
        position: "bottom",
        ...flashMessage,
        isClosable: true,
      });
    }
  }, [flashMessage]);
}
