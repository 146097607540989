import { json } from "@remix-run/node";
import {
  Outlet,
  useRouteError,
  useLoaderData,
  useNavigate,
} from "@remix-run/react";
import { useEffect } from "react";
import { withSentry, captureRemixErrorBoundaryError } from "@sentry/remix";
import { getUser } from "~/utils/session.server.js";
import { getFlashMessage } from "~/utils/flash.server.js";
import {
  ChakraProvider,
  cookieStorageManagerSSR,
  Button,
  Link,
  Text,
  Code,
} from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { ErrorMessage } from "~/components";
import Layout, { StaticLayout } from "./layout";
import Document from "./document";
import theme from "./theme";
import { useColorMode } from "~/hooks";

import globalStyles from "~/styles/global.css?url";
import fontStyles from "@fontsource/inter/variable.css?url";
import rdpStyles from "react-day-picker/dist/style.css?url";
import rdpOverrideStyles from "~/styles/rdpOverride.css?url";

export function meta() {
  return [
    { title: "Campground fully booked? Schnerp it | Schnerp" },
    {
      name: "description",
      content:
        "Tell us what campground you're looking for. We'll scan the reservation website every few minutes and notify you if a spot opens up.",
    },
  ];
}
export function links() {
  return [
    {
      rel: "stylesheet",
      href: globalStyles,
    },
    {
      rel: "stylesheet",
      href: fontStyles,
    },
    {
      rel: "stylesheet",
      href: rdpStyles,
    },
    {
      rel: "stylesheet",
      href: rdpOverrideStyles,
    },
  ];
}

export async function loader({ request }) {
  const user = await getUser(request);
  const { flashMessage, headers } = await getFlashMessage(request);

  return json(
    {
      env: {
        APP_ENV: process.env.APP_ENV,
        BASE_URL: process.env.BASE_URL,
        REWARDFUL_API_KEY: process.env.REWARDFUL_API_KEY,
        SENTRY_DSN: process.env.SENTRY_DSN,
        GIT_COMMIT_SHA: process.env.GIT_COMMIT_SHA || "development",
        VAPID_PUBLIC_KEY: process.env.VAPID_PUBLIC_KEY,
        POSTMARK_REBOUND_TOKEN: process.env.POSTMARK_REBOUND_TOKEN,
      },
      user,
      flashMessage,
      cookies: request.headers.get("cookie") ?? "",
    },
    {
      headers,
    },
  );
}

export async function action() {
  // Only used to reload the page
  return null;
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  const navigate = useNavigate();
  let { colorMode, cookies } = useColorMode();

  if (error?.status === 404) {
    return (
      <Document colorMode={colorMode}>
        <ChakraProvider
          theme={theme}
          colorModeManager={cookieStorageManagerSSR(cookies)}
        >
          <StaticLayout
            ActionButton={
              <Button variant="primary" onClick={() => navigate(-1)}>
                Go back
              </Button>
            }
          >
            <ErrorMessage
              status="warning"
              title="Page not found"
              description={
                <>
                  <Text>The page you're trying to access does not exist.</Text>
                  <Text mt={4}>
                    If you need any help, please email{" "}
                    <Link
                      isExternal
                      href="mailto:help@schnerp.com?Subject=Please%20help"
                    >
                      help@schnerp.com
                    </Link>{" "}
                    for assistance.
                  </Text>
                </>
              }
            />
          </StaticLayout>
        </ChakraProvider>
      </Document>
    );
  }

  if (error?.data?.error) {
    return (
      <Document colorMode={colorMode}>
        <ChakraProvider
          theme={theme}
          colorModeManager={cookieStorageManagerSSR(cookies)}
        >
          <StaticLayout
            ActionButton={
              <Button variant="primary" onClick={() => navigate(-1)}>
                Go back
              </Button>
            }
          >
            <ErrorMessage
              status="warning"
              title="Uh oh!"
              description={
                <>
                  <Text>{error.data.error}</Text>
                  <Text mt={4}>
                    If you need any help, please email{" "}
                    <Link
                      isExternal
                      href="mailto:help@schnerp.com?Subject=Please%20help"
                    >
                      help@schnerp.com
                    </Link>{" "}
                    for assistance.
                  </Text>
                </>
              }
            />
          </StaticLayout>
        </ChakraProvider>
      </Document>
    );
  }

  captureRemixErrorBoundaryError(error);

  return (
    <Document colorMode={colorMode}>
      <ChakraProvider
        theme={theme}
        colorModeManager={cookieStorageManagerSSR(cookies)}
      >
        <StaticLayout
          ActionButton={
            <Button variant="primary" onClick={() => window.location.reload()}>
              Reload
            </Button>
          }
        >
          <ErrorMessage
            title="Sorry, something went wrong"
            description={
              <>
                <Code my={6}>{error.message}</Code>
                <Text>
                  Our team has been notified of this error. Please reload the
                  page and try again.
                </Text>
                <Text mt={4}>
                  If the problem continues, please email{" "}
                  <Link
                    isExternal
                    href="mailto:help@schnerp.com?Subject=Please%20help"
                  >
                    help@schnerp.com
                  </Link>{" "}
                  for assistance.
                </Text>
                <Button
                  mt={4}
                  variant="primary"
                  onClick={() => window.location.reload()}
                >
                  Reload
                </Button>
              </>
            }
          />
        </StaticLayout>
      </ChakraProvider>
    </Document>
  );
};

const queryClient = new QueryClient();

function App() {
  let { env, user, cookies } = useLoaderData();

  let { colorMode } = useColorMode(cookies);

  return (
    <Document env={env} user={user} colorMode={colorMode}>
      <ChakraProvider
        theme={theme}
        colorModeManager={cookieStorageManagerSSR(cookies)}
      >
        <QueryClientProvider client={queryClient}>
          <Layout>
            <Outlet />
          </Layout>
        </QueryClientProvider>
      </ChakraProvider>
    </Document>
  );
}

export default withSentry(App);
