import { mode } from "@chakra-ui/theme-tools";

const variants = {
  accent: (props) => ({
    color: mode("brand.500", "brand.200")(props),
  }),
  menu: () => ({
    borderRadius: "lg",
    _hover: {
      textDecoration: "none",
      bg: "bg-subtle",
    },
  }),
};

export default {
  variants,
  defaultProps: {
    variant: "accent",
  },
};
