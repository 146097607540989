import { extendTheme } from "@chakra-ui/react";
import { theme as proTheme } from "./pro/dist/index.esm.js";

import * as foundations from "./foundations/index.js";
import * as components from "./components/index.js";

export default extendTheme(
  {
    ...foundations,
    colors: { ...proTheme.colors, brand: proTheme.colors.teal },
    components: {
      ...components,
    },
  },
  proTheme,
);
