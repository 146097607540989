import { Link as RemixLink } from "@remix-run/react";

import {
  Box,
  ButtonGroup,
  Container,
  IconButton,
  Stack,
  Text,
  Link,
} from "@chakra-ui/react";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { Logo } from "./Logo";

export const Footer = () => (
  <Container
    maxW="container.lg"
    as="footer"
    role="contentinfo"
    py={{ base: 12, md: 16 }}
    px={{ base: 6, md: 8 }}
  >
    <Stack spacing={{ base: "4", md: "5" }}>
      <Stack direction="row" justify="space-between" align="center">
        <Logo withPopover={true} />
        <ButtonGroup>
          <IconButton
            as={Link}
            href="https://www.instagram.com/_schnerp"
            isExternal
            aria-label="Instagram"
            variant="ghost"
            icon={<FaInstagram fontSize="1.25rem" />}
          />
          <IconButton
            as={Link}
            href="https://www.facebook.com/schnerp"
            isExternal
            aria-label="Facebook"
            variant="ghost"
            icon={<FaFacebook fontSize="1.25rem" />}
          />
        </ButtonGroup>
      </Stack>

      <Box>
        <Text fontSize="sm">
          Made local with ❤️ by{" "}
          <Link href="https://github.com/danthareja" isExternal>
            Daniel Thareja
          </Link>
        </Text>
        <Text fontSize="sm" color="subtle">
          &copy; {new Date().getFullYear()} All rights reserved.{" "}
          <Link as={RemixLink} to="/legal/terms" color="subtle">
            Terms
          </Link>
          <Text color="subtle" as="span">
            {" "}
            |{" "}
          </Text>
          <Link as={RemixLink} to="/legal/privacy" color="subtle">
            Privacy
          </Link>
        </Text>
      </Box>
      <Box></Box>
    </Stack>
  </Container>
);
