import { capitalize } from "lodash-es";

import { NavLink, Link as RemixLink } from "@remix-run/react";
import { Text, Badge, HStack } from "@chakra-ui/react";
import { createIcon } from "@chakra-ui/icons";

import { useOptionalUser } from "~/hooks";
import { SubscriptionBadge } from "~/components/Badges";
import { DefinitionPopover } from "./DefinitionPopover";

export function Logo({ withPopover = false }) {
  const user = useOptionalUser();

  if (!withPopover) {
    return (
      <HStack as={NavLink} to={user ? "/browse" : "/"} end={!user}>
        <LogoIcon color="accent" fontSize="36px" />
        <Text
          color="accent"
          fontSize="24px"
          fontWeight="extrabold"
          display={{ base: "none", md: "block" }}
        >
          Schnerp
        </Text>
        <SubscriptionBadge />
      </HStack>
    );
  }

  return (
    <DefinitionPopover
      trigger={
        <HStack as="button">
          <LogoIcon color="accent" fontSize="36px" />
          <Text color="accent" fontSize="24px" fontWeight="extrabold">
            Schnerp
          </Text>
          <SubscriptionBadge />
        </HStack>
      }
    />
  );
}

export const LogoIcon = createIcon({
  displayName: "SchnerpLogo",
  viewBox: "350 350 800.000000 800.000000",
  path: (
    <g
      transform="translate(0.000000,1500.000000) scale(0.100000,-0.100000)"
      fill="currentColor"
      stroke="none"
    >
      <path
        d="M7050 11030 c-262 -30 -357 -47 -605 -109 -115 -29 -147 -38 -265
      -78 -604 -203 -1132 -528 -1555 -957 -263 -267 -476 -561 -639 -882 -145 -287
      -234 -544 -302 -879 -73 -358 -73 -862 0 -1240 180 -926 742 -1738 1586 -2288
      491 -320 1065 -531 1660 -611 222 -30 323 -36 567 -36 308 0 492 16 773 66
      505 90 1036 299 1455 572 574 374 1012 856 1297 1427 116 235 186 420 249 665
      70 269 90 425 96 755 4 244 2 301 -16 440 -35 272 -71 435 -145 655 -64 191
      -92 260 -184 445 -213 425 -476 760 -884 1124 -268 240 -731 517 -1108 664
      -225 88 -526 177 -700 208 -30 6 -93 17 -140 25 -319 56 -818 71 -1140 34z
      m1985 -979 c423 -85 474 -120 447 -300 -19 -132 -74 -210 -225 -322 -68 -51
      -229 -139 -322 -176 -146 -58 -180 -70 -350 -121 -94 -29 -339 -90 -505 -127
      -137 -30 -154 -34 -385 -91 -247 -60 -309 -77 -536 -148 -300 -93 -434 -156
      -540 -254 -118 -109 -132 -235 -35 -320 89 -78 245 -127 516 -162 41 -6 127
      -17 190 -25 63 -9 151 -20 195 -25 44 -5 112 -14 150 -20 39 -5 126 -17 195
      -25 69 -9 159 -20 200 -25 41 -5 102 -12 135 -15 33 -3 96 -10 140 -15 44 -5
      150 -17 235 -25 231 -23 428 -51 605 -85 385 -75 527 -116 690 -195 189 -93
      270 -191 268 -324 -1 -52 -8 -82 -31 -132 -101 -221 -313 -341 -912 -514 -320
      -93 -601 -167 -740 -194 -48 -9 -205 -43 -310 -66 -36 -8 -141 -31 -235 -51
      -93 -20 -199 -42 -235 -50 -36 -8 -85 -19 -110 -24 -38 -7 -141 -31 -305 -70
      -110 -26 -210 -52 -310 -80 -60 -18 -150 -42 -200 -55 -357 -91 -541 -170
      -651 -280 -80 -79 -101 -150 -74 -244 10 -36 56 -91 76 -91 6 0 23 -9 38 -21
      51 -40 27 -110 -49 -139 -60 -23 -632 -10 -868 20 -78 10 -203 48 -243 75 -14
      9 -32 32 -40 50 -12 30 -12 40 2 82 52 155 273 288 732 442 81 27 183 59 227
      71 44 12 100 28 125 35 25 7 65 18 90 24 143 34 305 71 370 86 41 9 93 20 115
      25 59 14 160 36 245 55 41 9 94 20 118 26 23 5 98 21 165 34 67 14 154 32 192
      40 39 9 90 20 115 25 469 96 1063 265 1395 396 265 104 408 221 468 384 111
      298 -356 545 -1293 685 -58 9 -148 22 -200 30 -52 8 -162 21 -245 30 -82 8
      -188 20 -235 25 -47 5 -123 12 -170 15 -93 6 -247 19 -422 35 -62 6 -169 15
      -238 20 -545 41 -729 76 -895 174 -115 67 -150 123 -150 236 0 59 5 85 24 123
      47 95 173 199 335 277 73 34 359 132 596 204 85 25 565 143 780 191 476 106
      743 186 981 296 158 73 324 182 388 255 62 70 108 205 94 277 -7 35 19 74 60
      93 46 21 181 12 362 -25z"
      />
    </g>
  ),
});
